import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

const arrowDown: NodeListOf<HTMLElement> =
  document.querySelectorAll('[data-section]')

arrowDown.forEach((arrow) => {
  const section: HTMLElement = document.querySelector(
    `${arrow.dataset.section}`
  )!
  arrow.addEventListener('click', (event) => {
    event.preventDefault()
    section.scrollIntoView({ block: 'start', behavior: 'smooth' })
  })
})
