const popups: NodeListOf<HTMLElement> = document.querySelectorAll('.popup')

const linkPopup: NodeListOf<HTMLElement> =
  document.body.querySelectorAll('[data-popup]')

popups.forEach(($popup) => setClosePopupHandler($popup))

linkPopup.forEach(($link) => setOpenPopupHandler($link))

export function setOpenPopupHandler($link: HTMLElement) {
  $link.addEventListener('click', openPopupHandler)

  function openPopupHandler(event: MouseEvent) {
    event.preventDefault()

    if ($link.dataset.popup) {
      openPopup($link.dataset.popup)
    } else {
      console.error('Попап не найде')
    }
  }
}

export function openPopup(popupSelector: string) {
  const popup: HTMLElement = document.querySelector(popupSelector)!

  scrollLock()
  popup.classList.add('popup-open')
  document.addEventListener('keydown', isEscapeHandler)
}

export function setClosePopupHandler($popup: HTMLElement) {
  const $buttonClosePopup: HTMLElement | null = $popup.querySelector(
    '.popup__close-button'
  )

  $buttonClosePopup?.addEventListener('click', closeHandler)
  $popup.addEventListener('click', closeHandler)

  function closeHandler(event: MouseEvent) {
    if (
      $popup.classList.contains('popup-open') &&
      event.target instanceof HTMLElement &&
      (!event.target.closest('.popup__content') ||
        event.target.closest('.popup__close-button'))
    ) {
      scrollUnlock()
      $popup.classList.remove('popup-open')
      document.removeEventListener('keydown', isEscapeHandler)
    }
  }
}

function isEscapeHandler(event: KeyboardEvent) {
  if (event.key == 'Escape') {
    popups.forEach((popup) => {
      popup.classList.remove('popup-open')
    })
    scrollUnlock()
    document.removeEventListener('keydown', isEscapeHandler)
  }
}

function scrollLock() {
  document.body.classList.add('no-scroll')
}

function scrollUnlock() {
  document.body.classList.remove('no-scroll')
}
