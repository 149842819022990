export const metricsEvents = {
  REGISTRATION_LINK_BUTTON_CLICK: 'registration_link_button_click',
  MAIN_LOGO_CLICK: 'main_logo_click',
  LOGIN_BUTTON_CLICK: 'login_button_click',
  LOGIN_TEXT_CLICK: 'login_text_click',
  HEADER_DOWNLOAD_CLICK: 'header_download_click',
  IOS_DOWNLOAD_CLICK: 'ios_download_click',
  ANDROID_DOWNLOAD_CLICK: 'android_download_click',
  HUAWEI_DOWNLOAD_CLICK: 'huawei_download_click',
  SAMSUNG_DOWNLOAD_CLICK: 'samsung_download_click',
  XIAOMI_DOWNLOAD_CLICK: 'xiaomi_download_click',
  RUSTORE_DOWNLOAD_CLICK: 'rustore_download_click',
  RULES_CLICK: 'rules_click',
  VKONTAKTE_PM_ACCOUNT: 'vkontakte_pm_account',
  PARTICIPATION_FIELD_MESSAGE: 'participation_field_message',
  PARTICIPATION_FIELD_EMAIL: 'participation_field_email',
  PARTICIPATION_FIELD_ADRESS: 'participation_field_adress',
  PARTICIPATION_FIELD_PHONE: 'participation_field_phone',
  PARTICIPATION_FILE_UPLOAD: 'participation_file_upload',
  PARTICIPATION_ID_SENT_SUCCESS: 'participation_id_sent_success',
  PARTICIPATION_ID_SENT_BUTTON: 'participation_id_sent_button',
  NAVIGATION_ARROW_RIGHT: 'navigation_arrow_right',
  NAVIGATION_ARROW_LEFT: 'navigation_arrow_left',
} as const

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const handlersGtmEvent = {
  REGISTRATION_LINK_BUTTON_CLICK: () => {
    if (window.dataLayer) window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'registration',
      eventAction: 'registration_link_button_click',
      eventLabel: '{{Page URL}}',
    })
  },
  MAIN_LOGO_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'logo',
      eventAction: 'main_logo_click',
      eventLabel: '{{Page URL}}',
    })
  },
  LOGIN_BUTTON_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'login',
      eventAction: 'login_button_click',
      eventLabel: '{{Page URL}}',
    })
  },
  LOGIN_TEXT_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'login',
      eventAction: 'login_text_click',
      eventLabel: '{{Page URL}}',
    })
  },
  HEADER_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'header_download_click',
      eventLabel: '{{Referrer}}',
    })
  },
  IOS_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'ios_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  ANDROID_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'android_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  HUAWEI_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'huawei_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  SAMSUNG_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'samsung_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  XIAOMI_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'xiaomi_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  RUSTORE_DOWNLOAD_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'download',
      eventAction: 'rustore_download_click',
      eventLabel: '{{Page URL}}',
    })
  },
  RULES_CLICK: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'rules',
      eventAction: 'rules_click',
      eventLabel: '{{Page URL}}',
    })
  },
  VKONTAKTE_PM_ACCOUNT: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'social',
      eventAction: 'vkontakte_pm_account',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_FIELD_MESSAGE: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_field_message',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_FIELD_EMAIL: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_field_email',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_FIELD_ADRESS: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_field_adress',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_FIELD_PHONE: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_field_phone',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_FILE_UPLOAD: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_file_upload',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_ID_SENT_SUCCESS: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_id_sent_success',
      eventLabel: '{{Page URL}}',
    })
  },
  PARTICIPATION_ID_SENT_BUTTON: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'participation',
      eventAction: 'participation_id_sent_button',
      eventLabel: '{{Page URL}}',
    })
  },
  NAVIGATION_ARROW_RIGHT: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'navigation',
      eventAction: 'navigation_arrow_right',
      eventLabel: '{{Page URL}}',
    })
  },
  NAVIGATION_ARROW_LEFT: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'landing',
      eventCategory: 'navigation',
      eventAction: 'navigation_arrow_left',
      eventLabel: '{{Page URL}}',
    })
  },
}
