declare const registrationApi: Record<string, any>

window.addEventListener('load', () => {
  registrationApi.init('pariReg')

  registrationApi.onRegistrationCompleted = () => {
    const links: HTMLElement = document.querySelector(
      '.popup__content--register .register__link'
    )!
    links.style.display = 'none'
  }
})
