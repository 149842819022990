import { handlersGtmEvent, metricsEvents } from './metrics-list'

const dataLayerItems: NodeListOf<HTMLElement> =
  document.querySelectorAll('[data-layer]')

dataLayerItems.forEach(setDataLayer)

export function setDataLayer(element: HTMLElement) {
  const itemDataValue = element.dataset.layer

  for (const metricsEventsKey in metricsEvents) {
    if (metricsEvents[metricsEventsKey] === itemDataValue)
      element.addEventListener('click', handlersGtmEvent[metricsEventsKey])
  }
}
